import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { PortfolioCard } from '../containers';
import { medias } from '../utils/mediaQueries';
import { siteContent, bride } from '../services/api';
import {
  SupplierBudgetForm,
  SmallAdvantagesCard,
  RequestBudgetForm,
} from '../components';
import { Loader, RestrictAccessModal } from '../components';
import { PageWrapper } from '../components/pageComponents';
import { scrollToRef } from '../hooks';
import { useStateValue } from '../state';
import { isBrowser } from '../services/checkSession';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SupplierInfo = styled.div`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1``;

const Subtitle = styled.span`
  @media ${medias.phoneMax} {
    display: flex;
    flex-direction: column;
  }

  button,
  span {
    margin-right: 10px;
  }
`;

const NavigationBar = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #f1f2f2;
  border-top: 1px solid #f1f2f2;
  margin-top: 10px;

  span {
    @media ${medias.phoneMax} {
      width: 25%;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 150px;
    padding: 0 10px;
    padding-bottom: 2px;
    margin-right: 10px;

    :hover {
      padding-bottom: 0;
      border-bottom: 2px solid #efc874;
    }
  }
`;

const MainContainer = styled.div`
  @media ${medias.phoneMax} {
    flex-direction: column;
    align-items: center;
  }

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const CarouselContainer = styled.div``;

const FormContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border: 1px solid #040707;
  width: 25%;
  margin: 1em;
  max-height: ${props => (props.logged ? '380px' : 'auto')};
`;

const CardsContainer = styled.div`
  @media ${medias.phoneMax} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  display: grid;
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const InfoContainer = styled.section`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  display: flex;
  flex-direction: column;
  width: 70%;
`;

const InfoButton = styled.button`
  border: 1px solid #f1f2f2;
  border-radius: 8px;
  padding: 10px;
`;

const SupplierPage = props => {
  const [supplierData, setSupplierData] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [advantagesData, setAdvantagesData] = useState(null);
  const [restrictVisible, setRestrictVisible] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);
  const [instagramClicked, setInstagramClicked] = useState(false);
  const [
    {
      session: { selectedEvent, selectedRegion, category },
    },
  ] = useStateValue();

  const supplierId = props.pageContext
    ? props.pageContext.id
    : props.location.state.id;

  const portfolioRef = useRef(null);
  const aboutRef = useRef(null);
  const advantagesRef = useRef(null);
  const reviewsRef = useRef(null);

  const isLoggedIn = isBrowser() && !!localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      const response = await siteContent.getSupplier(supplierId);

      if (response.ok && response.data) {
        setSupplierData(response.data);
      }
    };

    fetchData();
  }, [supplierId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierPortfolio(supplierId);

      if (response.ok && response.data) {
        setPortfolioData(response.data);
      }
    };

    fetchData();
  }, [supplierId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierAdvantages(
        selectedEvent,
        supplierId
      );

      if (response.ok && response.data) {
        setAdvantagesData(response.data.advantages);
      }
    };

    fetchData();
  }, [supplierId, selectedEvent]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await bride.getSupplierSegments(supplierId);

      if (response.ok && response.data) {
        setSupplierData({
          ...supplierData,
          segmentos: response.data,
        });
      }
    };

    if (supplierData && !supplierData.segmentos) {
      fetchData();
    }
  }, [supplierId, supplierData]);

  const removeFavorite = async cod => {
    const response = await bride.removeFavorite(selectedEvent, 2, cod);
    if (!response.ok) {
      toast.error('Erro ao remover favorito');
    }
  };

  const addFavorite = async cod => {
    const response = await bride.addFavorite({
      codEvent: selectedEvent,
      codRegion: selectedRegion,
      codAdvantage: cod,
    });

    if (!response.ok) {
      toast.error('Erro ao favoritar benefício.');
    }
  };

  const handleClickBenefits = () => {
    if (isLoggedIn) {
      return scrollToRef(advantagesRef);
    }

    setRestrictVisible(true);
  };

  const handleEmailClick = () => {
    // @TODO: Add Google Analytics
    setEmailClicked(true);
  };

  const handlePhoneClick = () => {
    // @TODO: Add Google Analytics
    setPhoneClicked(true);
  };

  const handleInstagramClicked = () => {
    // @TODO: Add Google Analytics
    setInstagramClicked(true);
  };

  return (
    <PageWrapper>
      {supplierData && supplierData.segmentos && portfolioData ? (
        <Wrapper>
          <SupplierInfo>
            <Title>{supplierData.nomeDivulgacao}</Title>
            {isLoggedIn && (
              <Subtitle>
                {supplierData.emailNoivos && (
                  <>
                    {emailClicked ? (
                      <span>Email: {supplierData.emailNoivos}</span>
                    ) : (
                      <InfoButton onClick={handleEmailClick}>
                        Ver Email
                      </InfoButton>
                    )}
                  </>
                )}
                {supplierData.telCelularNoivos && (
                  <>
                    {phoneClicked ? (
                      <span>Telefone: {supplierData.telCelularNoivos}</span>
                    ) : (
                      <InfoButton onClick={handlePhoneClick}>
                        Ver Telefone
                      </InfoButton>
                    )}
                  </>
                )}
                {supplierData.instagram && (
                  <>
                    {instagramClicked ? (
                      <span>Instagram: {supplierData.instagram}</span>
                    ) : (
                      <InfoButton onClick={handleInstagramClicked}>
                        Ver Instagram
                      </InfoButton>
                    )}
                  </>
                )}
              </Subtitle>
            )}
          </SupplierInfo>
          <NavigationBar>
            <span onClick={() => scrollToRef(portfolioRef)}>
              Fotos & Videos
            </span>
            <span onClick={() => scrollToRef(aboutRef)}>Sobre</span>
            <span onClick={handleClickBenefits}>Benefícios</span>
            <span onClick={() => scrollToRef(reviewsRef)}>Depoimentos</span>
          </NavigationBar>
          <MainContainer>
            <InfoContainer>
              <CarouselContainer ref={portfolioRef}>
                <PortfolioCard key={1} content={portfolioData} noButton />
              </CarouselContainer>
              <section ref={aboutRef}>
                <h3>Sobre</h3>
                {supplierData.resumo}
              </section>
            </InfoContainer>
            {supplierData && (
              <FormContainer logged={isLoggedIn}>
                {isLoggedIn ? (
                  <SupplierBudgetForm
                    supplierData={supplierData}
                    selectedEvent={selectedEvent}
                  />
                ) : (
                  <RequestBudgetForm supplierCod={supplierId} />
                )}
              </FormContainer>
            )}
          </MainContainer>
          {isLoggedIn && (
            <section ref={advantagesRef}>
              <h3>Benefícios</h3>
              {advantagesData ? (
                <CardsContainer>
                  {advantagesData.map(advantage => (
                    <SmallAdvantagesCard
                      key={advantage.codAdvantage}
                      advantageData={advantage}
                      category={category}
                      removeFavorite={removeFavorite}
                      addFavorite={addFavorite}
                    />
                  ))}
                </CardsContainer>
              ) : (
                <Loader />
              )}
            </section>
          )}
          <section ref={reviewsRef}>
            <h3>Depoimentos</h3>
          </section>
        </Wrapper>
      ) : (
        <Loader />
      )}
      <RestrictAccessModal
        onClickClose={() => setRestrictVisible(false)}
        text="Estes benefícios estão disponíveis apenas para noivos que já fazem parte da Vestidas de Branco! Quer saber como fazer parte e ter acesso a condições imperdíveis com nossos fornecedores? Clique no botão abaixo!."
        buttonText="FAÇA PARTE"
        link="/facaparte/noivo"
        opened={restrictVisible}
      />
    </PageWrapper>
  );
};

export default SupplierPage;
